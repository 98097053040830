.secondary-card {
    width: 32rem;
    height: 32rem;
    position: relative;
    display: block;
    cursor: pointer;
    transition: var(--transition);
}

.secondary-card__info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: brightness(100%);
    opacity: 0;
    color: var(--gray);
    text-align: center;
    transition: var(--transition);
}

.secondary-card__name {
    font-size: 2rem;
    line-height: 120%;
    font-weight: 500;
}

.secondary-card__location {
    font-size: 1.5rem;
    line-height: 150%;
    margin-top: 1.8rem;
    max-width: 24.2rem;
}

.secondary-card:hover .secondary-card__info {
    opacity: 1;
    transition: var(--transition);
}

