.card {
    width: 35rem;
    background-color: var(--white);
    filter: drop-shadow(0 0.4rem 0.4rem rgba(0, 0, 0, .1));
    border-radius: 0.5rem;
    color: var(--black);
}

.card header,
.card img  {
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}

.card header {
    height: 60%;
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    font-size: 1.7rem;
}

.card p:first-child {
    font-weight: 600;
}

.card p:nth-child(2) {
    text-transform: uppercase;
    font-weight: 400;
    margin: 2rem 0 4rem 0;
}


/* ---- Media queries ---- */
@media (max-width: 500px) {
    .card {
        width: 100%;
    }
}