.carousel .slide img {
    object-fit: cover;
}

.carousel-status {
    display: none;
}


/* ---- Media queries ---- */
@media (max-width: 992px) {
    .carousel-root {
        margin-top: 9rem;
    }
}