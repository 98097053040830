header .container {
    display: flex;
    align-items: center;
    padding: 1rem 1.8rem;
    justify-content: space-between;
}

.header-nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: var(--transition);
}

.header-transparent {
    background-color: transparent;
}

.header-bg {
    background-color: var(--black);
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,.5);
}

.header-nav .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-nav nav ul {
    display: flex;
    gap: 5rem;
}

.header-nav nav ul li a {
    display: flex;
    position: relative;
    text-transform: uppercase;
    font-size: 1.8rem;
}

/* Efeito underline no hover */
.header-nav nav ul li a.active:after,
.header-nav nav ul li a:after {
    content: "";
    position: absolute;
    background-color: var(--red);
    height: 0.2rem;
    bottom: -0.6rem;
    transition: var(--transition);
}

.header-nav nav ul li a.active:after {
    width: 100%;
}

.header-nav .social-media {
    display: none;
}


/* ---- Media queries ---- */
@media (max-width: 992px) {
    .header-nav {
        background-color: var(--black) !important;
        box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,.2) !important;
    }

    .header-logo {
        width: 7rem;
        height: 7rem;
    }

    .header-logo img {
        width: 100%;
        height: 100%;
    }

    .header-nav nav {
        display: none;
    }

    .menu-toggle {
        width: 4rem;
        height: 3rem;
    }

    .one,
    .two,
    .three {
        width: 100%;
        height: 0.5rem;
        margin: 0.6rem auto;
        background-color: var(--white);
        transition-duration: 0.3s;
        border-radius: 0.5rem;
    }

    .menu-section.on {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: var(--black);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-nav .menu-section.on nav {
        display: block;
        margin-bottom: 8rem;
    }

    .menu-section.on .menu-toggle {
        position: absolute;
        right: 2rem;
        top: 3rem;
    }

    .menu-section.on .menu-toggle .one {
        transform: rotate(45deg) translate(0.7rem, 0.7rem);
    }

    .menu-section.on .menu-toggle .two {
        opacity: 0;
    }

    .menu-section.on .menu-toggle .three {
        transform: rotate(-45deg) translate(0.8rem, -0.9rem);
    }

    .header-nav .menu-section.on nav ul {
        text-align: center;
        display: block;
        z-index: 9999;
    }

    .header-nav .menu-section.on nav ul a {
        display: block;
        transition-duration: 0.5s;
        font-size: 3rem;
        line-height: 10rem;
    }

    .header-nav nav ul li a.active:after,
    .header-nav nav ul li a:after {
        display: none;
    }

    .header-nav nav ul li a.active:after {
        width: 100%;
    }

    .header-nav .social-media {
        display: flex;
        gap: 6rem;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 10rem;
        left: 0;
        right: 0;
    }
}