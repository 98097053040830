/* ---- Animation ---- */
@keyframes fadeUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(4rem);
              transform: translateY(4rem);
    }
}

.feedback-content {
    display: none;
}

.feedback-content.slider--active {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feedback-content .feedback-img {
    width: 18rem;
    height: auto;
}

.feedback-content .feedback-img img {
    width: 100%;
    clip-path: circle();
    -webkit-animation: fadeUp 0.2s linear backwards;
    animation: fadeUp 0.2s linear backwards;
}

.feedback-content .feedback-text {
    max-width: 80%;
    margin: 3rem 0;
    text-align: center;
    line-height: 2.3rem;
    -webkit-animation: fadeUp 0.2s linear 0.6s backwards;
    animation: fadeUp 0.2s linear 0.6s backwards;
}

.feedback-content .feedback-text p:last-child {
    margin-top: 3rem;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-animation: fadeUp 0.2s linear 0.8s backwards;
    animation: fadeUp 0.2s linear 0.8s backwards;
}

.feedback-arrows {
    display: flex;
    gap: 3rem;
}

.feedback-arrows .arrow--left,
.feedback-arrows .arrow--right {
    cursor: pointer;
}

.arrow--left {
    transform: rotate(-180deg);
}


/* ---- Media queries ---- */
@media (max-width: 960px) {
    .feedback-content .feedback-text {
        text-align: justify;
    }
}

@media (max-width: 600px) {
    .feedback-content .feedback-text {
        max-width: 100%;
    }
}