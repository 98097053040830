.individual-film-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.individual-film-content .name {
    font-size: 2.8rem;
    font-weight: 600;
    margin: 0;
}

.individual-film-content .location-date {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.individual-film-content .description {
    max-width: 90rem;
    margin-top: 5rem;
    line-height: 2.2rem;
    text-align: justify;
}

.individual-film-content .video {
    margin: 3rem 0;
    width: 90rem;
    height: 50.6rem;
}

.individual-film-content .video iframe {
    width: 100%;
    height: 100%;
}

.container-related-individual-media {
    max-width: 124rem;
    padding: 0 1.8rem;
    height: 100%;
    margin: 0 auto;
    margin-bottom: 7rem;
}

.secondary-cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
}

/* ---- Media queries ---- */
@media (max-width: 768px) {
    .individual-film .video {
        width: 100%;
        height: 22rem;
    }

    .individual-film-content .name {
        font-size: 2.4rem;
    }
}