.about .container {
    min-height: calc(100vh - 32.8rem);
}

.about-subtitle {
    text-align: center;
}

.about-subtitle > div {
    position: relative;
    display: inline-block;
}

.about-subtitle h2 {
    text-transform: uppercase;
    letter-spacing: 0.3rem;
}

.about-subtitle p {
    margin-top: 2rem;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 2.1rem;
}

.about-text {
    margin-top: 8rem;
}

.about-text p {
    line-height: 2.2rem;
    font-size: 1.7rem;
}

.about-text p:nth-child(2) {
    margin: 2.5rem 0;
}