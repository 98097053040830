/* ---- Main ---- */
.collage-photos-grid {
    display: grid;
    gap: 1rem;
    grid-template-areas:
        "photo1 photo1 photo4"
        "photo2 photo3 photo4"
        "photo5 photo5 photo5"
        "photo5 photo5 photo5"
        "photo6 photo7 photo8";
}

.collage-photos-info {
    display: none;
}

.collage-photos-info p {
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 2rem;
}

.photo {
    position: relative;
    transition: var(--transition);
}

.photo:hover .collage-photos-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: var(--white);
}

.photo img {
    width: 100%;
    transition: var(--transition);
}

.photo:hover img {
    filter: brightness(35%);
}

.photo1 {
	grid-area: photo1;
}

.photo2 {
	grid-area: photo2;
}

.photo3 {
	grid-area: photo3;
}

.photo4 {
	grid-area: photo4;
    background: url('../../assets/images/home/trabalhos-img4.jpg');
    background-size: cover;
    background-position: -2.5rem;
    cursor: pointer;
    transition: var(--transition);
}

.photo4:hover {
    background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url('../../assets/images/home/trabalhos-img4.jpg');
}

.photo5 {
	grid-area: photo5;
}

.photo6 {
	grid-area: photo6;
}

.photo7 {
	grid-area: photo7;
}

.photo8 {
	grid-area: photo8;
}

.photo2 img,
.photo3 img {
    height: 100.1%;
}


/* ---- Section feedback ---- */
.feedback {
    background-color: var(--white);
}

/* ---- Section awards ---- */
.awards .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.awards .awards-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
}


/* ---- Media queries ---- */
@media (max-width: 510px) {
    .awards .awards-content {
        flex-direction: column;
    }
}