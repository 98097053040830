footer {
    background-color: var(--black);
    font-size: 1.65rem;
    color: var(--white);
}

footer a,
footer p {
    font-size: 16px;
}

footer .container {
    display: flex;
    flex-direction: column;
    padding: 0 1.8rem;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 7rem;
}

.footer-links {
    display: flex;
    gap: 15rem;
}

footer nav ul {
    text-transform: uppercase;
}

footer nav ul,
.footer-social-media,
.footer-contacts  {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.footer-social-media p,
.footer-contacts p {
    text-transform: uppercase;
}

.footer-social-media a,
.footer-contacts a {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.footer-copyright {
    margin: 5rem 0 2.5rem 0;
}

.footer-copyright span {
    height: 0.1rem;
    width: 100%;
    display: inline-block;
    background-color: rgba(192,192,192,0.9);
}

.footer-copyright p {
    margin-top: 1rem;
}


/* ---- Media queries ---- */
@media (max-width: 960px) {
    .footer-content {
        gap: 3rem;
        flex-direction: column-reverse;
    }

    .footer-links {
        justify-content: center;
        flex-wrap: wrap;
        gap: 8rem;
    }
}

@media (max-width: 702px) {
    .footer-links {
        justify-content: inherit;
        gap: 6rem;
    }

    .footer-social-media {
        margin-right: 4rem;
    }

    .footer-copyright {
        margin: 3rem 0 2.5rem 0;
    }
}