@import url('https://fonts.googleapis.com/css2?family=Amaranth&family=Roboto:wght@300;400;500;600;700&display=swap');

:root {
    --black: #18161A;
    --white: #FAFAFA;
    --red: #CE0000;
    --gray: #F0F0F0;
    --gray-dark: #696969;
    --gray-form: #CCC;
    --toastify-color-success: #029a06;

    --font-roboto: 'Roboto', sans-serif;
    --font-amaranth: 'Amaranth', sans-serif;
    --transition: all .3s ease-in;
    font-size: 62.5%;
}

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    position: relative;
}

body {
    background-color: var(--gray);
    color: var(--black);
    font-size: 1.6rem;
    font-family: var(--font-roboto);
}

h2 {
    font-family: var(--font-amaranth);
    font-size: 3rem;
    font-weight: 600;
}

.container-fluid,
.container {
    height: 100%;
    margin: 0 auto;
}

.container-fluid {
    max-width: 90%;
    padding: 0 1.8rem;
}

.container {
    max-width: 124rem;
    padding: 7rem 1.8rem;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: var(--white);
}

.m-left8 {
    margin-left: 0.8rem;
}

.m-top10 {
    margin-top: 1rem;
}

.m-top110 {
    margin-top: 11rem;
}

.m-top30 {
    margin-top: 3rem;
}

.d-none {
    display: none;
}

.d-flex-column-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

input,
textarea,
input::placeholder,
textarea::placeholder {
    font-family: var(--font-roboto);
}

.btn {
    padding: 2rem 3.5rem;
    border: none;
    border-radius: 0.5rem;
    background-color: var(--black);
    color: var(--white);
    font-size: 1.7rem;
    font-family: var(--font-roboto);
    font-weight: 600;
    font-size: 1.6rem;
    cursor: pointer;
    transition: var(--transition);
}

.btn:hover {
    opacity: 0.9;
}

/* ---- Media queries ---- */
@media (max-width: 992px) {
    h2 {
        font-size: 2.3rem;
    }
}

@media (max-width: 768px) {
    p {
        font-size: 1.5rem;
    }

    .container {
        padding: 5rem 2rem;
    }
}