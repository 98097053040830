.whatsapp-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 3.5rem;
    right: 3.5rem;
    z-index: 9998;
}

.whatsapp-popup {
    max-width: 38.5rem;
    display: flex;
    gap: 2rem;
    padding: 1.5rem;
    margin-bottom: 1.8rem;
    border-radius: 0.5rem;
    background-color: var(--white);
}

.whatsapp-popup p {
    font-size: 1.45rem;
}

.whatsapp-popup > div {
    cursor: pointer;
}

.whatsapp-button {
    align-self: flex-end;
}


/* ---- Media queries ---- */
@media (max-width: 992px) {
    .whatsapp-container {
        right: 2rem;
    }

    .whatsapp-popup {
        display: none;
    }
}