.title {
    padding-bottom: 5.5rem;
    text-align: center;
}


/* ---- Media queries ---- */
@media (max-width: 992px) {
    .title {
        padding-bottom: 4rem;
    }
}


