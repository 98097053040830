.individual-photo .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.individual-photo .main-photo {
    width: 90%;
    margin-top: 10rem;
    padding: 2rem;
    background-color: #FFF;
    box-shadow: 0px 3.2rem 12.9rem rgba(3, 7, 18, 0.07);

}

.individual-photo .main-photo img {
    width: 100%;
    height: auto;
}

.main-photo div:last-child {
    text-align: center;
}

.main-photo div:last-child p:first-child {
    margin-top: 3rem;
    font-weight: 700;
    font-size: 2.4rem;
}

.main-photo div:last-child p:not(:first-child) {
    color: var(--gray-dark);
}

.main-photo div:last-child p:nth-child(2) {
    margin: 1.5rem 0;
}

.other-photos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;
}

.other-photos div {
    width: 85%;
    margin-bottom: 1rem;
}

.other-photos div img {
    width: 100%;
}

.pointer-none {
    pointer-events: none;
}

/* ---- Media queries ---- */
@media (max-width: 768px) {
    .individual-photo .main-photo,
    .other-photos div {
        width: 95%;
    }
}