form .required-marker {
    color: var(--red);
    margin-left: 0.3rem; 
}

form .error-text {
    color: var(--red);
    font-size: 1.5rem;
}

form .error-input {
    border: 0.1rem solid var(--red);
}

form .error-input:active,
form .error-input:focus {
    outline-color: var(--red);
    box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.075) inset, 0 0 0.8rem rgba(206, 0, 0, 1);
}

.form-name-email,
.form-phone-event {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
}

.form-phone-event {
    margin: 3rem 0 3rem 0;
}

.form-name-email div,
.form-phone-event div,
.form-message {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

form input,
form textarea {
    min-width: 30rem;
    padding: 1.2rem 2rem;
    background-color: var(--gray);
    border: 0.1rem solid var(--gray-form);
    border-radius: 5px;
    color: var(--black);
}

form textarea {
    resize: vertical;
}

form input:active,
form input:focus,
form textarea:active,
form textarea:focus {
  outline-color: var(--black);
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.075) inset, 0 0 0.8rem rgba(88, 152, 236, 0.6);
}

.form-send-btn-wrapper {
    display: flex;
    margin-top: 4rem;
}

.form-send-btn-wrapper button {
    width: 100%;
}


/* ---- Media queries ---- */
@media (max-width: 712px) {
    .form-name-email, 
    .form-phone-event {
        display: block;
    }
    
    form input[name="name"],
    form input[name="phone"] {
        margin-bottom: 2rem;
    }
}

@media (max-width: 392px) {  
    form input,
    form textarea {
        min-width: 2rem !important;
    }
}




