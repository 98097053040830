.not-found {
    height: 100vh;
}

.not-found .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.not-found .container div:first-child {
    max-width: 60rem;
} 

.not-found div:first-child p {
    margin: 2rem 0 5rem 0;
    line-height: 2.5rem;
    color: #232126;
    font-size: 1.7rem;
}


/* ---- Media queries ---- */
@media (max-width: 910px) {
    .not-found .container {
        flex-direction: column-reverse;
        justify-content: flex-end;
        gap: 5rem;  
    }

    .not-found .button-wrapper a {
        display: block;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 570px) {
    .not-found .img-wrapper {
        width: 45rem;
    }

    .not-found .img-wrapper img {
        width: 100%;
    }
}

@media (max-width: 540px) {
    .not-found {
        height: auto;
        min-height: 100vh;
    }
}

@media (max-width: 470px) {
    .not-found .img-wrapper {
        width: 40rem;
    }
}

@media (max-width: 420px) {
    .not-found .img-wrapper {
        width: 35rem;
    }
}

@media (max-width: 370px) {
    .not-found .img-wrapper {
        width: 32rem;
    }
}