.active--tab {
    font-weight: 700;
}

.filter .line {
    width: 100%;
    height: 0.1rem;
    margin: 0 auto;
    display: block;
    background-color: rgba(200, 200, 200, .8);
}

.filter .line:first-child {
    margin-bottom: 2rem;
}

.filter .line:last-child {
    margin-top: 2rem;
}

.filter-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5.5rem;
    text-transform: uppercase;
    font-size: 1.7rem;
}

.filter-content p {
    cursor: pointer;
    font-size: 1.7rem;
}

.cards-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    justify-content: space-between;
    row-gap: 4.5rem;
    margin-top: 7rem;
}


/* ---- Media queries ---- */
@media (max-width: 1084px) {
    .cards-wrapper {
        justify-content: center;
        column-gap: 6rem;
    }
}

@media (max-width: 791px) {
    .cards-wrapper {
        column-gap: 3rem;
    }
}

@media (max-width: 676px) {
    .filter-content {
        gap: 4rem;
    }
}