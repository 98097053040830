.contact-form-wrapper {
    max-width: 75rem;
    margin: 0 auto;
    padding: 3rem;
    background-color: var(--white);
    border: 0.1rem solid var(--gray-form);
    border-radius: 1rem;
}

.contact-form-wrapper p {
    margin-bottom: 5rem;
    text-align: center;
    font-weight: 500;
    font-size: 1.7rem; 
}

.form-social-media {
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-top: 5rem;
}

.custom-toast {
    margin-top: 80px;
    font-family: var(--font-roboto);
}


